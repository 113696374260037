import React from 'react';
import SocialIcon from './SocialIcon';

function SocialLinks() {
    return (<>
        <a href="https://www.facebook.com/profile.php?id=100083018838113" target="_new" className="text-gray-400 hover:text-gray-500">
            <span className="sr-only">Facebook</span>
            <SocialIcon name="facebook" className="h-6 w-6" />
        </a>
        <a href="https://www.instagram.com/coffeeweb.studio/" target="_new" className="text-gray-400 hover:text-gray-500">
            <span className="sr-only">Instagram</span>
            <SocialIcon name="instagram" className="h-6 w-6" />
        </a>
    </>)
}
export default SocialLinks
