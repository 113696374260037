import React from 'react';
import SocialLinks from './SocialLinks';


function Footer({ companyName }) {
    const currentYear = new Date().getFullYear()

    return (
        <footer className='py-4 bg-orange-700 bg-blend-multiply bg-opacity-5' aria-labelledby="footer-heading">
            <div className="mx-auto max-w-7xl px-6 py-4">
                <div className="md:flex md:items-center md:justify-between">
                    <div className="flex space-x-6 md:order-2">
                        <SocialLinks />
                    </div>
                    <p className="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
                        &copy; {currentYear} {companyName}.
                    </p>
                </div>
            </div>
        </footer>
    )
}
export default Footer
