import { Popover } from '@headlessui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import SocialLinks from './SocialLinks';


function Header({ companyName }) {

    return (
        <header className='sticky top-0 z-20 shadow-lg'>
            <Popover className="relative bg-white">
                <div className="mx-auto flex max-w-7xl items-center justify-between lg:p-1 md:justify-start md:space-x-10 pl-2 pr-6 lg:px-8">
                    <div className="flex justify-start lg:w-0 lg:flex-1">
                        <Link to="/" className='flex'>
                            <span className="sr-only">{companyName}</span>
                            <img
                                className="h-16 lg:h-20 w-auto sm:h-10 flex"
                                src="icons/apple-touch-icon.png"
                                alt=""
                            />
                            <div className='flex flex-row items-center'>
                                <div className="flex-col text-center">
                                    <span className='flex flex-col'>CoffeeWeb</span>
                                    <span className='flex flex-col'>studio</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    {/* <div className="-my-2 -mr-2 md:hidden">
                        <SocialLinks />
                    </div> */}

                    <div className="items-center justify-end lg:w-0 gap-2 flex">
                        <SocialLinks />
                    </div>
                </div>

            </Popover>
        </header>
    )
}
export default Header
