import React from 'react';


function HeroSection({ backgroundImage }) {
    return (
        <div className="relative">
            <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
            <div className="mx-auto">
                <div className="relative shadow-xl sm:overflow-hidden">
                    <div className="absolute inset-0">
                        <img
                            className="h-full w-full object-cover"
                            src={backgroundImage}
                            alt=""
                        />
                        <div className="absolute inset-0 bg-gradient-to-r from-slate-600 to-orange-300 mix-blend-multiply" />
                    </div>
                    <div className="relative py-16 px-6 sm:py-24 lg:py-32 lg:px-8">
                        <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
                            <span className="block text-white">Administramos tu web</span>
                        </h1>
                        <p className="mx-auto mt-6 max-w-lg text-center text-xl text-white sm:max-w-3xl">
                            Para que tú te enfoques en tu negocio.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HeroSection
