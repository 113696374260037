/*
  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import Footer from "../components/Footer"
import Header from '../components/Header'
import HeroSection from "../components/HeroSection"

export default function LandingPage({ currentYear, companyName }) {
    return (
        <div className="bg-white">

            <Header companyName={companyName} />

            <main className="shadow-lg">
                {/* Hero section */}
                <HeroSection backgroundImage="images/home.jpg" />

                {/* Alternating Feature Sections */}
                <div className="relative overflow-hidden pt-16 pb-32">
                    <div aria-hidden="true" className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100" />
                    <div className="relative">
                        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
                            <div className="mx-auto max-w-xl px-6 lg:mx-0 lg:max-w-none lg:py-16 lg:px-0">
                                <div>
                                    <div className="mt-6">
                                        <h2 className="text-3xl font-bold tracking-tight text-yellow-700">
                                            Nuestro enfoque
                                        </h2>
                                        <p className="mt-4 text-lg text-gray-500 text-justify">
                                            Nos dedicamos al desarrollo web, creamos sitios para empresas y personas. Siempre orientados a mantener a los clientes actuales y cautivar nuevos.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-12 sm:mt-16 lg:mt-0">
                                <div className="-mr-48 pl-6 md:-mr-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                                    <img
                                        className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                        src="images/que-hacemos.jpg"
                                        alt="Inbox user interface"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-24">
                        <div className="lg:mx-auto lg:grid lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-2 lg:gap-24 lg:px-8">
                            <div className="mx-auto max-w-xl px-6 lg:col-start-2 lg:mx-0 lg:max-w-none lg:py-32 lg:px-0">
                                <div>
                                    <div className="mt-6">
                                        <h2 className="text-3xl font-bold tracking-tight text-yellow-700">
                                            Dale a tu marca el valor que se merece
                                        </h2>
                                        <p className="mt-4 text-lg text-gray-500">
                                            Tu sitio web tiene el potencial de agregar valor a tu marca o de dañar tu reputación.</p>
                                        <p className="mt-4 text-lg text-gray-500">
                                            No es una tarea sencilla ni mucho menos corta, pero lo podemos lograr haciendo equipo.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-12 sm:mt-16 lg:col-start-1 lg:mt-0">
                                <div className="-ml-48 pr-6 md:-ml-16 lg:relative lg:m-0 lg:h-full lg:px-0">
                                    <img
                                        className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                                        src="images/estrategia.jpg"
                                        alt="Customer profile user interface"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer companyName={companyName} />
        </div>
    )
}
